import '../assets/styles/maiia.scss'; // Side-effects
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import dayjsPluginUtc from 'dayjs/plugin/utc';
import dayjsPluginTimezone from 'dayjs/plugin/timezone';
import dayjsPluginIsBetween from 'dayjs/plugin/isBetween';
import dayjsPluginMinMax from 'dayjs/plugin/minMax';
import dayjsPluginDuration from 'dayjs/plugin/duration';
import dayjsPluginObjectSupport from 'dayjs/plugin/objectSupport';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr'; // Side-effects
import { setConfig as setPatSharedConfig } from '@maiia/pat-shared';
import React, { useEffect } from 'react';
import {
  RootStateOrAny,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import CookieStorage from 'js-cookie';
import { ThemeProvider } from '@mui/material/styles';

import { compose } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  Error as ErrorComponent,
  MaintenanceStatusWrapper,
  RefreshToken,
} from '@docavenue/components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';

// eslint-disable-next-line import/no-extraneous-dependencies

import { NextSeo } from 'next-seo';
import { appWithTranslation } from 'next-i18next';

import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ApiClientProvider } from '@maiia/model/generated/core/react-query';
import { makeQueryKey as makeQueryKeyFeatureProperties } from '@maiia/model/generated/model/api-patient/hooks/patPublicApplicationSettingsFeatureProperties';
import { CustomAppProps } from '@/types/page.types';
import GTMPageView, { setTrackingUserId } from '../src/analytic_tag_manager';
import { wrapper, getToken, getEncryptionInfos } from '@/src/store';
import Layout from '../components/templates/Main';
import config from '../src/config';
import { getApiClient, setupApiClient } from '../src/http/client';
import { makeQueryClient } from '../src/query/client';
import { authActions, geoipActions } from '../src/actions';
import theme from '../src/theme';
import { pick } from '@/src/utils';
import DefaultSeo from '../components/templates/Main/DefaultSeo';
import DocavenueDetector from '../components/templates/Main/DocavenueDetector';
import Maintenance from '../components/templates/Main/Maintenance'; // Disable load dynamic in case disconnected network
import WithAuth from '../components/molecules/WithAuth/WithAuth';
import HealthcheckDetector from '@/components/templates/Main/HealthcheckDetector';
import TimeOffsetDetector from '@/components/templates/Main/TimeoffsetDetector';
import Referral from '@/components/organisms/Referral/Referral';
import screeb, {
  isScreebLoaded,
  screebCleanQueue,
  screebInit,
} from '@/src/screeb';
import nextI18nConfig from '../next-i18next.config';
import { useTranslation } from '@/src/i18n';
import '../global.css';

setupApiClient({ getToken, getEncryptionInfos });

/* Side-effects */
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(dayjsPluginUtc);
dayjs.extend(dayjsPluginTimezone);
dayjs.extend(dayjsPluginIsBetween);
dayjs.extend(dayjsPluginMinMax);
dayjs.extend(dayjsPluginDuration);
dayjs.extend(dayjsPluginObjectSupport);
dayjs.extend(dayjsCustomParseFormat);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(LocalizedFormat);
setPatSharedConfig({
  BCB_ENDPOINT: config.get('BCB_ENDPOINT'),
  SERVER_BCB_ENDPOINT: config.get('SERVER_BCB_ENDPOINT'),
});
/* end side-effects */

const ErrorDialog = dynamic(() =>
  import('../components/organisms/ErrorDialog/ErrorDialog'),
);

const LinkTokenDetector = dynamic(
  () => import('../components/templates/Main/LinkTokenDetector'),
  { ssr: false },
);

const AMCDetector = dynamic(
  () => import('../components/templates/Main/AMCDetector'),
  { ssr: false },
);

const Cookies = dynamic(
  () => import('../components/organisms/Cookies/Cookies'),
  { ssr: false },
);

const LogoutDetector = dynamic(
  () => import('../components/templates/Main/LogoutDetector'),
  { ssr: false },
);

const Websocket = dynamic(
  () => import('../components/templates/Main/Websocket'),
  { ssr: false },
);

const uidSelector = (state: RootStateOrAny) =>
  state.authentication.item?.id as string | undefined;

/**
 * Set custom path for KIIWI_FONTAWESOME_ICON_SPRITE_PATH
 *
 * Default is '/fontawesome-sprites.svg', but we serve it at
 * '/static/images/fontawesome-sprites-kiiwi.svg'
 *
 * Ref: https://gitlab.cegedim-sante.com/maiia-services/maiia-applications/maiia-kiiwi/-/blob/fc1f1a270393f5c25d59d1089b31db8ab51e8166/packages/components/src/FontawesomeIcon/index.tsx#L92
 */
if (typeof window !== 'undefined') {
  (window as any).KIIWI_FONTAWESOME_ICON_SPRITE_PATH =
    '/static/images/fontawesome-sprites-kiiwi.svg';
}

const MyApp = (props: CustomAppProps) => {
  const { Component, pageProps, err, emotionCache } = props;
  const router = useRouter();
  const store = useStore();
  const [queryClient] = React.useState(() =>
    makeQueryClient(
      { getApiClient },
      {
        staleTime: 5 * 60 * 1000, // 5 min
        retry: 2,
      },
    ),
  );
  const uid = useSelector(uidSelector);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  /**
   * The || 'fr' part forces the french locale because the next-i18next cookie
   * isn't set as "SameSite=None", which prevents it to be shared in an iframe context.
   * A better fix would be to set the cookie's sameSite to none from the i18n config but
   * I wasn't able to make it work :(
   */
  dayjs.locale(CookieStorage.get('next-i18next') || 'fr');

  useEffect(() => {
    if (config.get('ENVK8S') !== 'production') {
      // eslint-disable-next-line no-console
      console.log('routeChangeComplete', router?.pathname);
    }
    GTMPageView(router.asPath, router || undefined);
    window.scrollTo(0, 0);
    // Invalidate all inactive queries when change page
    queryClient.invalidateQueries({ type: 'inactive', refetchType: 'none' });
  }, [router.asPath]);

  useEffect(() => {
    document.documentElement.classList.add('render');
  }, []);

  useEffect(() => {
    import('../src/cypressHelpers').then(lib => {
      if ((window as any).Cypress) (window as any).Cypress.helpers = lib;
    });
  }, []);

  useEffect(() => {
    if (uid) {
      setTrackingUserId(uid);
    }
  }, [uid]);

  useEffect(() => {
    if (
      !(
        config.get('ENVK8S') === 'production' ||
        config.get('SCREEB_ENABLED') === 'true'
      )
    )
      return;
    if (!uid) return;

    const initScreeb = async () => {
      if (!isScreebLoaded()) await screeb.load();
      if (!window.$screebInitialized) {
        screebInit(
          dispatch,
          config.get('SCREEB_WEBSITE_ID') ||
            '6a07941f-8d07-4be4-aa4d-379a1cd16eec',
          {
            onReady: () => {
              window.$screebInitialized = true;
              screebCleanQueue();
            },
            version: '1.0.0',
          },
        );
      }
    };
    if ((window as any).tarteaucitron.launch.screeb) {
      initScreeb();
      return;
    }
    document.addEventListener('screeb_allowed', initScreeb);
    return () => {
      document.removeEventListener('screeb_allowed', initScreeb);
    };
  }, [uid]);

  useEffect(() => {
    queryClient.prefetchQuery({ queryKey: makeQueryKeyFeatureProperties() });
  }, []);

  // load translations message and cache on client side
  useEffect(() => {
    i18n.reloadResources(i18n.language, ['common', 'error']);
  }, []);

  // geoip from user ip
  useEffect(() => {
    fetch('/api/location-from-ip')
      .then(res => res.json())
      .then(res => {
        if (res.longitude) {
          dispatch(geoipActions.setLocation(res));
        }
      });
  }, []);

  if (err) {
    return (
      <AppCacheProvider emotionCache={emotionCache}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} key={router.route} />
        </ThemeProvider>
      </AppCacheProvider>
    );
  }
  const requireAuthenticated = typeof Component.authenticated !== 'undefined';

  const authConfig = pick(
    Component,
    'authenticated',
    'suppressFirstRenderFlicker',
    'redirectAuthenticatedTo',
  );

  const isServer = typeof window === 'undefined';
  const baseUrl =
    config.get('HOSTNAME') || (!isServer && (window as Window).location.origin);

  return (
    <ApiClientProvider getApiClient={getApiClient}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <DndProvider backend={Backend}>
            <PersistGate persistor={(store as any).__persistor}>
              {bootstrapped =>
                (isServer || bootstrapped) && (
                  <>
                    <RefreshToken
                      onResetSuccess={state => {
                        const { token, refresh } = state.refresh?.item || {};
                        const account = state.authentication.item;
                        return authActions.setItem({
                          ...account,
                          token,
                          refresh,
                        });
                      }}
                    />
                    <ErrorComponent
                      component={ErrorDialog}
                      whitelistsPatterns={[
                        'appointments/**',
                        'deleteUserRequests/CREATE/**',
                        'profiles/**',
                        'stripe/**',
                        'paymentsIntents/CREATE/**',
                        'videoSessions/CREATE/*/!(APPOINTMENT_ALREADY_DONE)',
                        'documents/CREATE/**',
                        'linkAccounts/CREATE/*/(PATIENT_ACCOUNT_ALREADY_LINKED|PATIENT_LINK_ACCOUNT_NOT_MATCHED_PHONE)',
                        'waitingProposals/CREATE/**',
                        'videoSessions/OT/**',
                      ]}
                    />
                    <DefaultSeo baseUrl={baseUrl} />
                    <DocavenueDetector />
                    <LinkTokenDetector />
                    <LogoutDetector />
                    <HealthcheckDetector />
                    <TimeOffsetDetector />
                    <Websocket />
                    <AppCacheProvider emotionCache={emotionCache}>
                      <ThemeProvider theme={theme}>
                        <WithAuth {...authConfig}>
                          <AMCDetector />
                          <MaintenanceStatusWrapper
                            maintenancePage={<Maintenance />}
                          >
                            <Layout>
                              <Component {...pageProps} key={router.route} />
                              <Cookies />
                              <Referral />
                            </Layout>
                          </MaintenanceStatusWrapper>
                        </WithAuth>
                      </ThemeProvider>
                    </AppCacheProvider>
                    {requireAuthenticated && <NextSeo noindex nofollow />}
                    <ReactQueryDevtools />
                  </>
                )
              }
            </PersistGate>
          </DndProvider>
        </Hydrate>
      </QueryClientProvider>
    </ApiClientProvider>
  );
};

export default compose(wrapper.withRedux, appWithTranslation)(
  MyApp,
  nextI18nConfig,
);
